export interface BoardState{
    boards: Board[]
    board: Board | null
}

export interface Board{
    boardId: number
    title: string
    content: string
    boardWriter: string
    regDate: string
    updDate: string
}

const state: BoardState = {
    boards: [],
    board: null
}

export default state