import { ActionContext } from "vuex"
import { Board, BoardState } from "./states"
import { AxiosResponse } from "axios"
import { REQUEST_BOARD_LIST_TO_DJANGO } from "./mutation-types"
import axiosInst from "@/utility/axiosInstance"

export type BoardActions = {
    requestBoardToDjango(context: ActionContext<BoardState, any>, boardId: number): Promise<void>
    requestBoardListToDjango(context: ActionContext<BoardState, any>): Promise<void>
    requestCreateBoardToDjango(context: ActionContext<BoardState, any>, payload: {
        title: string, writer: string, content: string
    }): Promise<AxiosResponse>

}

const actions: BoardActions = {
    async requestBoardToDjango(context: ActionContext<BoardState, any>, boardId: number): Promise<void> {
        try {
            const res: AxiosResponse<Board> = await axiosInst.djangoAxiosInst.get(`/board/read/${boardId}`);
            console.log('data:', res.data)
            context.commit('REQUEST_BOARD_TO_DJANGO', res.data);
        } catch (error) {
            console.error('requestBoardToDjango() 문제 발생:', error);
            throw error
        }
    },
    async requestBoardListToDjango(context: ActionContext<BoardState, any>): Promise<void> {
        try {
            const res: AxiosResponse<any, any> = await axiosInst.djangoAxiosInst.get('/board/list/')
            const data: Board[]= res.data
            context.commit(REQUEST_BOARD_LIST_TO_DJANGO, data)
        }catch(error){
            console.error('requestBoardListToDjango(): '+ error)
            throw error
        }
    },
    async requestCreateBoardToDjango(context: ActionContext<BoardState, unknown>, payload: {
        title: string, writer: string, content: string
    }): Promise<AxiosResponse>{

        const {title, writer, content} = payload
        console.log('전송할 데이터:', {title, writer, content})

        try{
            const res: AxiosResponse = await axiosInst.djangoAxiosInst.post('/board/register', {title, writer, content})

            console.log('res:', res.data)
            return res.data
        }catch(error){
            alert('requestCreateBoardToDjango() 문제 발생!')
            throw error
        }      
    },
};

export default actions