<template lang="">
    <v-container>
        <h2>Board</h2>
        <div style="text-align: left; margin: 15px;">
            <router-link :to="{ name:'BoardRegisterPage'}">
                게시물 작성
            </router-link>
        </div>
        <v-data-table
                v-model:item-per-page="perPage"
                :headers="headerTitle"
                :items="pageItems"
                v-model:pagination="pagination"
                class="elevation-1"
                item-value="boardId"/>
            <v-pagination
                v-model="pagination.page"
                :length="Math.ceil(boards.length / perPage)"
                color="primary"
                @input="updateItems"/>
    </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'

const boardModule = 'boardModule'

export default{
    components:{
    },
    computed:{
        ...mapState(boardModule, ['boards']),
        pageItems(){
            
            const startIdx = (this.pagination.page - 1)*this.perPage
            const endIdx = startIdx + this.perPage
            return this.boards.slice(startIdx, endIdx)
        }
    },
    mounted () {
        this.requestBoardListToDjango()
    },
    methods: {
        ...mapActions(boardModule, ['requestBoardListToDjango']),
    },
    data(){
        return{
            headerTitle: [
                {
                    title: 'No',
                    align: 'start',
                    sortable: true,
                    key: 'boardId'
                },
                {title: '제목', align: 'end', key: 'title'},
                {title: '작성자', align: 'end', key: 'writer'},
                {title: '작성일자', align: 'end', key: 'regDate'},
            ],
            perPage: 5,
            pagination: {
                page: 1,
            }
        }
    }
}
</script>